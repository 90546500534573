import { FormattedMessage } from 'react-intl';
import { Link, twMerge, Size } from '@pledge-earth/product-language';

import { ExternalLink } from '../../../../components/Link/ExternalLink';
import { CookieYesLink } from '../../../../components/CookieYesLink/CookieYesLink';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';

function generateVariant(
  isAboveMedium: boolean,
  isAboveLarge: boolean,
  isErrorFooter: boolean,
  requiresLargeBreakpoint: boolean,
) {
  let variant: 'contrast-brand' | 'monochrome' = 'contrast-brand';
  if (requiresLargeBreakpoint) {
    variant = !isErrorFooter && isAboveLarge ? 'contrast-brand' : 'monochrome';
  } else {
    variant = !isErrorFooter && isAboveMedium ? 'contrast-brand' : 'monochrome';
  }
  return variant;
}

export function Footer({
  className,
  isErrorFooter = false,
  requiresLargeBreakpoint = false,
}: {
  className?: string;
  isErrorFooter?: boolean;
  requiresLargeBreakpoint?: boolean;
}) {
  const isAboveMedium = useMediaQuery('(min-width: 680px)');
  const isAboveLarge = useMediaQuery('(min-width: 1128px)');
  const variant = generateVariant(isAboveMedium, isAboveLarge, isErrorFooter, requiresLargeBreakpoint);
  const breakColor = variant === 'monochrome' ? 'text-default-bold' : 'text-contrast-brand';

  return (
    <div
      className={twMerge(
        'flex flex-col items-start gap-2 bg-transparent pb-4 sm:flex-row sm:items-center',
        isErrorFooter ? 'justify-center' : 'pl-4',
        className,
      )}
    >
      <ExternalLink
        className={twMerge(isErrorFooter && 'text-default-bold')}
        noExternalIcon={true}
        href="https://www.pledge.io/terms"
        size={Size.Compact}
        variant={variant}
      >
        <FormattedMessage id="share.footer.terms" />
      </ExternalLink>
      <span className={twMerge(breakColor, 'hidden sm:flex')}>&bull;</span>
      <ExternalLink
        className={twMerge(isErrorFooter && 'text-default-bold')}
        noExternalIcon={true}
        href="https://www.pledge.io/privacy"
        size={Size.Compact}
        variant={variant}
      >
        <FormattedMessage id="share.footer.privacy-policy" />
      </ExternalLink>
      <span className={twMerge(breakColor, 'hidden sm:flex')}>&bull;</span>
      <CookieYesLink>
        {(props) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            {...props}
            href="#"
            className={twMerge(isErrorFooter && 'text-default-bold')}
            size={Size.Compact}
            variant={variant}
          >
            <FormattedMessage id="cookie-settings" />
          </Link>
        )}
      </CookieYesLink>
    </div>
  );
}
